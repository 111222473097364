<template>
    <layout-full-with-nav>
        <router-view />

        <template #navbar="{ toggleVerticalMenuActive }">
            <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </template>
    </layout-full-with-nav>
</template>

<script>
    import LayoutFullWithNav from '@core/layouts/layout-full-with-nav/LayoutFullWithNav.vue'
    import Navbar from '@/layouts/components/NavbarNoCashRegister.vue'
    export default {
        components: {
            LayoutFullWithNav,
            Navbar
        }
    }
</script>
