<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <language-dropdown />
                    <BBadge class="d-none d-sm-flex align-items-center nav-badge-no-cash" variant="light" pill>
                        <div class="d-sm-flex user-nav test">
                            <p v-if="userData" class="user-name font-weight-bolder mb-0">
                                {{ userData.name || userData.email }}
                            </p>
                        </div>
                        <BAvatar variant="primary" :text="userNameInitials"/>
                    </BBadge>
                    <BAvatar class="d-sm-none" variant="primary" :text="userNameInitials"/>
                </template>
                <b-dropdown-item link-class="d-flex align-items-center" @click="$refs.password_change_modal.showModal()">
                    <feather-icon
                        size="16"
                        icon="KeyIcon"
                        class="mr-50"
                    />
                    <span>{{$t('password.change_password')}}</span>
                </b-dropdown-item>
                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>{{ $t('login.logout2') }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>

        </b-navbar-nav>

        <ChangePasswordModal ref="password_change_modal"/>
        <CashRegisterStatus ref="cashRegisterStatusButton"></CashRegisterStatus>
    </div>
</template>

<script>
    import {
        BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BBadge
    } from 'bootstrap-vue'
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import CashRegisterStatus from '@/views/components/CashRegisterStatus/CashRegisterStatus.vue'
    import ChangePasswordModal from './ChangePasswordModal.vue'
    import LanguageDropdown from '@/components/LanguageDropdown.vue'

    export default {
        components: {
            LanguageDropdown,
            BBadge,
            CashRegisterStatus,
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BAvatar,
            // Navbar Components
            DarkToggler,
            ChangePasswordModal
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        computed: {
            userData() {
                return this.$store.getters['user/userData']
            },
            userNameInitials() {
                if (this.userData) {
                    const name = this.userData.name ?? this.userData.email
                    if (!name || name.length === 0) return ''

                    const names = name.split(' ')
                    let initials = names[0].charAt(0).toUpperCase()

                    if (names.length === 1) initials += names[0].charAt(1).toUpperCase()
                    else if (names.length > 1) initials += names[1].charAt(0).toUpperCase()
                    return initials
                }
                return ''
            }
        },
        methods: {
            openCashRegisterStatus() {
                this.$refs.cashRegisterStatusButton.open()
            },
            async logout() {
                try {
                    await this.$store.dispatch('user/logout')
                    await this.$router.replace('/login')
                } catch (error) {
                    this.$printError(this.$t('print.login.error_on_logout'))
                }
            }
        },
        mounted() {
            if (this.userData) {
                this.selected_client = this.userData.client_id
            }
        }
    }
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.nav-badge-no-cash {
  color: rgba(34, 41, 47, 0.5);
}
.dark-layout .nav-badge-no-cash {
  background: $theme-dark-body-bg;
  color: white;
}
</style>
