<template>
    <div
        class="vertical-layout h-100"
        :class="[layoutClasses]"
        :data-col="'1-column'"
    >
        <!-- Navbar -->
        <b-navbar
            :toggleable="false"
            :variant="navbarBackgroundColor"
            class=" header-navbar navbar navbar-shadow align-items-center"
            :class="[navbarTypeClass]"
        >
            <slot
                name="navbar"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :navbarBackgroundColor="navbarBackgroundColor"
                :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
            >
                <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
            </slot>
        </b-navbar>

        <!-- Content -->
        <!-- CONTENT TYPE: Left -->
        <div class="h-100 px-2 pb-2" style="padding-top: 100px;">

            <router-view />
        </div>

        <slot name="customizer" />
    </div>
</template>

<script>
    import { onUnmounted } from '@vue/composition-api'
    import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
    import useAppConfig from '@core/app-config/useAppConfig'
    import { BNavbar } from 'bootstrap-vue'
    import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
    import mixinVerticalLayout from '@core/layouts/layout-vertical/mixinVerticalLayout'

    export default {
        components: {
            AppNavbarVerticalLayout,
            BNavbar
        },
        mixins: [mixinVerticalLayout],
        computed: {
            layoutContentRenderer() {
                const rendererType = this.$route.meta.contentRenderer
                if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
                if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
                return 'layout-content-renderer-default'
            }
        },
        setup() {
            const {
                routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden
            } = useAppConfig()

            const {
                toggleVerticalMenuActive,
                layoutClasses,
                overlayClasses,
                resizeHandler,
                navbarTypeClass,
                footerTypeClass
            } = useVerticalLayout(navbarType, footerType)

            // Resize handler
            resizeHandler()
            window.addEventListener('resize', resizeHandler)
            onUnmounted(() => {
                window.removeEventListener('resize', resizeHandler)
            })

            return {
                toggleVerticalMenuActive,
                overlayClasses,
                layoutClasses,
                navbarTypeClass,
                footerTypeClass,

                // App Config
                routerTransition,
                navbarBackgroundColor,
                isNavMenuHidden
            }
        }
    }
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
